import './AboutSection.css';
import headshot from '../assets/damian_headshot.jpg'

export default function AboutSection() {
    return (
        <section id='about-section'>
            <div className='background-signs'>
                <h1>ABOUT</h1>
                <h1>ME</h1>
            </div>
            <div className='about-container'> 
                <div className='about-inner-container'>
                    <div className='headshot-container'>
                        <img src={headshot} alt="damian-wiecek-web-developer" />
                    </div>
                    <h2>Hi, I'm Damian</h2>
                    <p>
                    I’m a based in London journalism graduate, who is using his fascination with front-end web development to elevate his digital marketing skills. Do you need help putting together a website? Take photos at an event? Retouch portraits? Or maybe you need someone to create graphics for your socials? I’m here for you for all of those things!
                    <br /> <br />
                    I love getting creative and brainstorming new ideas with other people. If I sound like someone your team would benefit from, let's chat! 
                    </p>
                </div>
                    <a href='#' type='button' className='resume-btn btn'>Resume</a>
            </div>
        </section>
    )
}